/** Presets **/

// Fonts
$system-stack:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
$text:              "Montserrat", Montserrat, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
$ff-main: $text;


// Break points
$bp_small: 480px;
$bp_medium: 767px;
$bp_large: 1120px;
$bp_exlarge: 1200px;
$bp_xxlarge: 1440px;

// Break points (old)
$one: $bp_small; // small-screens
$two: $bp_medium; // medium-screens
$three: $bp_large; // large-screens
$four: $bp_exlarge; // exlarge-screens
$five: $bp_xxlarge; // xxlarge-screens

// rare/custom breakpoints
$bp_355: 355px; // "the space below 360px" (which has large mobile market share)
$bp_370: 370px;
$bp_388: 388px; // most of Figma templates for mobile are made in 390px, so this breakpoint is "the space below 390px"
$bp_480: 480px;
$bp_1260: 1260px;
$bp_1350: 1350px;
$bp_1600: 1600px;


// Page layout
$base:         6px;
$full_cont:    1200px; 
$text_cont:    672px;
$semi_cont:    960px;
$col-gut:      36px;
$screen_limit: 1600px;


// Margins
$inpage-margin-base:    $base*2;
$pad:                   $base*4;
$scrollbar: var(--scroll-bar-width);


$border-radius: 3px;

$side_padding_mobile: 24px;
$side_padding_desktop: 30px;


// These helpers/mixins are for custom @media conditions (when we need a custom breakpoint for a particular case)
@function mq_part_for($from) {
  @return 'min-width: ' + $from;
}

@function mq_part_between($from, $to) {
    @return '(min-width: ' + $from + ') and (max-width: ' + ($to - 1) + ')';
}

@function mq_part_upto($to) {
  @return 'max-width: ' + ($to - 1);
}


/* Media queries - utilities */

@mixin mq_upto($to) {
    @media screen and (max-width: $to - 1) { @content; }
}

@mixin mq_for($from) {
    @media screen and (min-width: $from) { @content; }
}

@mixin mq_between($from, $to) {
    @media screen and (min-width: $from) and (max-width: $to - 1) { @content; }
}


/* Media queries - utilities (legacy) */
@mixin for($media) {
    @if $media == small-screens {
        @media screen and (min-width: $one) { @content; }
    }
    @else if $media == medium-screens {
        @media screen and (min-width: $two) { @content; }
    }
    @else if $media == large-screens {
        @media screen and (min-width: $three) { @content; }
    }
    @else if $media == exlarge-screens {
        @media screen and (min-width: $four) { @content; }
    }
    @else if $media == xxlarge-screens {
        @media screen and (min-width: $five) { @content; }
    }
}

@mixin upto($media) {
    @if $media == small-screens {
        @media screen and (max-width: $one - 1) { @content; }
    }
    @else if $media == medium-screens {
        @media screen and (max-width: $two - 1) { @content; }
    }
    @else if $media == large-screens {
        @media screen and (max-width: $three - 1) { @content; }
    }
    @else if $media == exlarge-screens {
        @media screen and (max-width: $four - 1) { @content; }
    }
    @else if $media == xxlarge-screens {
        @media screen and (max-width: $five - 1) { @content; }
    }
}

@mixin between($bottom, $top) {
    @if $bottom == small-screens and $top == medium-screens {
        @media screen and (min-width: $one) and (max-width: $two - 1) { @content; }
    }
    @else if $bottom == small-screens and $top == large-screens {
        @media screen and (min-width: $one) and (max-width: $three - 1) { @content; }
    }
    @else if $bottom == medium-screens and $top == large-screens {
        @media screen and (min-width: $two) and (max-width: $three - 1) { @content; }
    }
    @else if $bottom == medium-screens and $top == exlarge-screens {
        @media screen and (min-width: $two) and (max-width: $four - 1) { @content; }
    }
    @else if $bottom == large-screens and $top == exlarge-screens {
        @media screen and (min-width: $three) and (max-width: $four - 1) { @content; }
    }
}



/* Helpers */
@function value_from_map($key, $map) {
    @if map-has-key($map, $key) {
        @return map-get($map, $key);
    }

    @warn "Unknown '#{$key}' in $map.";
    @return null;
}

@mixin hidden { //Hide for both screenreaders and browsers
    display: none;
    visibility: hidden; 
}

@mixin visuallyhidden { //Hide only visually, but have it available for screenreaders
    position : absolute;
    clip : rect(0 0 0 0);
    overflow : hidden;
    margin: -1px;
    padding : 0;
    height : 1px;
    width : 1px;
    border : 0; 
}

@mixin screen-reader {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

.sr-only { // screen-reader only
    @include screen-reader();
}

@mixin no-select {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

@mixin cf { // clearfix
    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

@mixin transition-all {
    transition: all .35s;
}


// Support for CSS min/max/clamp function for gulp sass
@function css-function( $function, $values... ) {
    @return 
        $function
        + unquote( '(' )
        + $values
        + unquote( ')' )
    ;
}

@function css-min( $values... ) {
    @return css-function( min, $values );
}

@function css-max( $values... ) {
    @return css-function( max, $values );
}

@function css-clamp( $values... ) {
    @return css-function( clamp, $values );
}


// Animations 

@keyframes fadeIn {
  0%   { 
    display: none;
    opacity: 0; 
  }
  1%{
    display: block; 
    opacity: 0; 
  }
  100% { 
    display: block; 
    opacity: 1; 
  }
}

@keyframes opacityIn {
  0%   { 
    opacity: 0; 
  }
  100% { 
    opacity: 1; 
  }
}

@keyframes fadeOut {
  0%   { 
    display: block;
    opacity: 1; 
  }
  99%{
    display: block; 
    opacity: 0; 
  }
  100% { 
    display: none; 
    opacity: 0; 
  }
}
